<template>
  <div>
    <!-- Table Families Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="app-action">
        <b-row>
          <b-col
            cols="6"
            md="6"
           
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
            <b-col
            cols="6"
            md="6"
         
          >
            <label>
              نوع الطلب
            </label>
                <v-select
            
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sortOptions"
                  v-model="sort"
                    :reduce="(val) => val.value"
                  
                
                   />
            
          </b-col>

        </b-row>

      </div>

      <!-- Update Mail Folder Dropdown -->

      <b-table
        ref="refFamiliesListTable"
        :items="fetchRequestItem"

        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :current-page="currentPage"
        :per-page="perPage"
      >

        <!-- Column: editfield -->

        <!-- Column: Actions -->
        <template #cell(action_id)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'update-request-item', params: { id: data.item.id } }">
              <feather-icon icon="Edit2Icon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>
             <b-dropdown-item :to="{ name: 'read-request-item', params: { id: data.item.id } }">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض </span>
            </b-dropdown-item>
          </b-dropdown>

        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'

import useRequestItemList from './useRejectedRequestItemList'
import financeStoreModule from './financeStoreModule.js'

export default {
  setup() {
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })
    // Mail Selection
    // ------------------------------------------------
const sortOptions=[ {
          label: 'انتظار',
          value: 1,
        },
        {
          label: 'سابقة ',
          value: 2,
        },
         {
          label: 'قيد التنفيذ ',
          value: 4,
        },
        {
          label: 'حالة جديدة بحاجة توزيع  ',
          value: 5,
        },
        {
          label: ' مرفوضة ',
          value: 3,
        },]

    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,
sort,
      fetchRequestItem,

      selectedItem,
      // Extra Filters
    } = useRequestItemList()

    return {
      // Sidebar
sortOptions,
sort,
      search: null,
      fetchRequestItem,

      filterOn: [],
      searchTerm: '',
      tableColumns,
      selectedItem,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

    }
  },

  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BFormCheckbox,
  },
  methods: {
    approveAll() {
      // console.log(this.selectedItem)
    },
  },
}
</script>

  <style scoped>
  div {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  </style>
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  .app-action {
    margin: 18px;
  }
  </style>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
